import React from "react";

const CollectMyCash = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4073_628)">
        <path
          d="M7.89204 4.87188C7.55813 5.10993 7.1495 5.24996 6.70817 5.24996C5.58059 5.24996 4.6665 4.33587 4.6665 3.20829C4.6665 2.08071 5.58059 1.16663 6.70817 1.16663C7.43908 1.16663 8.08028 1.5507 8.44097 2.12804M3.49984 11.7175H5.02251C5.22104 11.7175 5.41835 11.7411 5.61081 11.7883L7.21969 12.1793C7.56879 12.2644 7.93246 12.2726 8.2852 12.2041L10.0641 11.858C10.534 11.7665 10.9663 11.5415 11.305 11.2119L12.5636 9.98762C12.923 9.63859 12.923 9.07221 12.5636 8.72258C12.24 8.4078 11.7276 8.37236 11.3615 8.63931L9.89469 9.70946C9.68462 9.86301 9.42902 9.94569 9.16613 9.94569H7.74971L8.65129 9.94565C9.15946 9.94565 9.57109 9.54523 9.57109 9.05091V8.87196C9.57109 8.4615 9.28392 8.1036 8.87471 8.00438L7.48318 7.66598C7.25673 7.61105 7.0248 7.58329 6.79167 7.58329C6.22886 7.58329 5.21011 8.04927 5.21011 8.04927L3.49984 8.76447M11.6665 3.79163C11.6665 4.91921 10.7524 5.83329 9.62484 5.83329C8.49726 5.83329 7.58317 4.91921 7.58317 3.79163C7.58317 2.66404 8.49726 1.74996 9.62484 1.74996C10.7524 1.74996 11.6665 2.66404 11.6665 3.79163ZM1.1665 8.51663L1.1665 11.9C1.1665 12.2267 1.1665 12.39 1.23008 12.5148C1.28601 12.6245 1.37525 12.7138 1.48501 12.7697C1.60979 12.8333 1.77314 12.8333 2.09984 12.8333H2.5665C2.8932 12.8333 3.05655 12.8333 3.18133 12.7697C3.29109 12.7138 3.38033 12.6245 3.43626 12.5148C3.49984 12.39 3.49984 12.2267 3.49984 11.9V8.51663C3.49984 8.18993 3.49984 8.02658 3.43626 7.9018C3.38033 7.79204 3.29109 7.7028 3.18133 7.64687C3.05655 7.58329 2.8932 7.58329 2.5665 7.58329L2.09984 7.58329C1.77314 7.58329 1.60979 7.58329 1.48501 7.64687C1.37525 7.7028 1.28601 7.79204 1.23008 7.9018C1.1665 8.02658 1.1665 8.18993 1.1665 8.51663Z"
          stroke="#667085"
          strokeWidth="1.17"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4073_628">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CollectMyCash;
