import React from "react";
import PackageSvg from "../../assets/svg/PackageSvg";

const PackageCard = ({ number }) => {
  return (
    <div
      className={"p-3 bg-white rounded-2xl flex flex-col justify-between w-24"}
    >
      {/*    icon   */}
      <PackageSvg />

      <div>
        <div className={"text-sm text-gray-600 mt-5"}>pending packages</div>
        <p className={"font-semibold text-md"}>{number}</p>
      </div>
    </div>
  );
};

export default PackageCard;
