import axios from "axios";
import { BASE_URL } from "../Config";

const makePackagePayment = async data => {
  const res = await axios.put(BASE_URL.concat('/package-payment/web/'),data);

  return res?.data;
};

const verifyPayment = async data => {
  const res = await axios.post(BASE_URL.concat('/transaction_query_web'), data);

  return res?.data;
};

const makeShelfPayment = async data => {
	const res = await axios.post(BASE_URL.concat('/shelf/payments/pay-web'), data);

	return res?.data;
};

const verifyShelfPayment = async data => {
	const res = await axios.put(BASE_URL.concat('/shelf/payments/verification-web'), data);

	return res?.data;
};

const PaymentServices = {
	makePackagePayment,
	verifyPayment,
	makeShelfPayment,
	verifyShelfPayment,
};

export default PaymentServices;
