import axios from "axios";
import { BASE_URL } from "../Config";


const getPackageDetails = async (type,receipt) => {
  const response = await axios.get(
    `${BASE_URL}/collection-package/${type}/${receipt}`
  );

  return response?.data;
};

const PackageServices = {
	getPackageDetails,
};

export default PackageServices;
