import React from "react";

const Video = () => {
  return (
    <div
      className={" px-2 mr-4 md:mr-0 md:px-4 py-4 md:py-0 md:pt-36"}
      id={"about"}
    >
      <video
        className={
          "mx-auto h-96 w-full object-contain rounded-3xl md:rounded-[78px] "
        }
        loop
        controls={true}
        src={require("../../assets/videos/main-video.mp4")}
        style={{ height: "100%", objectFit: "cover" }}
      />
    </div>
  );
};

export default Video;
