import React from "react";
import {
  Carousel,
  Contact,
  Delivery,
  Footer,
  FrequentQuestions,
  Services,
  TrackingSection,
  Video,
  WhoChoseUs,
} from "../components/dashboard";

const Dashboard = () => {
  return (
    <div className={"flex-1 bg-white_bg w-screen "}>
      <div className={"md:px-8 w-screen "}>
        {/*  Carousel  */}
        <Carousel />

        {/*    Services     */}
        <Services />

        {/*/!*    Delivery  *!/*/}
        <Delivery />

        {/*/!*    Tracking    *!/*/}
        <TrackingSection />

        {/*/!*    Testimonials     *!/*/}
        <WhoChoseUs />

        {/*/!*    Video  *!/*/}
        <Video />

        {/*/!*    Frequently asked questions   *!/*/}
        <FrequentQuestions />
      </div>

      {/*    Contact us section   */}
      <Contact />

      {/*  footer  */}
      <Footer />
    </div>
  );
};

export default Dashboard;
