import React from "react";

const PlusSvg = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1798 5.70789V20.6517M5.70789 13.1798H20.6517"
        stroke="#667085"
        strokeWidth="2.1391"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusSvg;
