import React from "react";

const DetailItem = ({ icon, title, desc }) => {
  return (
    <div className={"flex gap-x-3"}>
      {/*    icon*/}
      <div
        className={
          "bg-gray-200 rounded-full h-7 w-7 flex items-center justify-center"
        }
      >
        {icon}
      </div>

      <div className={""}>
        <h6 className={"text-sm text-gray-700"}>{title}</h6>

        <p className={"font-medium text-sm mt-1"}>{desc}</p>
      </div>
    </div>
  );
};

export default DetailItem;
