import React from "react";
import PackageBoxSvg from "../../../assets/svg/PackageBoxSvg";

const PackageDetails = ({
  handleViewReceipt,
  disabled,
  packageNumber,
  customer,
}) => {
  return (
    <div
      className={
        "p-4 rounded-2xl bg-white border border-gray-200 w-full flex gap-x-3 items-center hover:bg-slate-50"
      }
    >
      <div className={"flex-shrink-0"}>
        <div
          className={
            "h-12 w-12 flex items-center justify-center bg-zinc-50 rounded-xl"
          }
        >
          <PackageBoxSvg />
        </div>
      </div>

      <button
        className={`flex-1 ${disabled && "cursor-not-allowed"}`}
        onClick={handleViewReceipt}
        disabled={disabled}
      >
        <div className={"flex gap-x-3 items-center"}>
          <h5 className={"font-bold text-base"}>#{packageNumber}</h5>
        </div>

        <div className={"flex space-x-1 items-center mt-1"}>
          <span className={"font-medium text-gray-500 text-[13px] "}>To: </span>
          <span className={"text-[13px] font-normal text-gray-500"}>
            {customer ?? ""}
          </span>
        </div>
      </button>
    </div>
  );
};

export default PackageDetails;
