import React from "react";

const PackageBoxSvg = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 12.5V22.5H4.5V12.5M12.5 22.5V7.5M12.5 7.5H8C7.33696 7.5 6.70107 7.23661 6.23223 6.76777C5.76339 6.29893 5.5 5.66304 5.5 5C5.5 4.33696 5.76339 3.70107 6.23223 3.23223C6.70107 2.76339 7.33696 2.5 8 2.5C11.5 2.5 12.5 7.5 12.5 7.5ZM12.5 7.5H17C17.663 7.5 18.2989 7.23661 18.7678 6.76777C19.2366 6.29893 19.5 5.66304 19.5 5C19.5 4.33696 19.2366 3.70107 18.7678 3.23223C18.2989 2.76339 17.663 2.5 17 2.5C13.5 2.5 12.5 7.5 12.5 7.5ZM2.5 7.5H22.5V12.5H2.5V7.5Z"
        stroke="#F9C516"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PackageBoxSvg;
