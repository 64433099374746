import React from "react";
import CloseSvg from "../../../assets/svg/CloseSvg";
import { COLORS } from "../../../assets/Theme";
import PackageJourney from "../order/PackageJourney";

const JourneyPane = ({ isOpen, handleClose }) => {
  return (
    <>
      <div
        className={`absolute inset-0 bg-gray-950 opacity-50 z-20 duration-300 ease-in-out transition-all ${
          isOpen ? "translate-x-0" : "translate-x-[250%]"
        } md:hidden`}
      />
      <div
        className={`absolute right-0 md:right-[370px] top-24 md:top-5 bottom-5 bg-gray-50 w-full md:w-[330px] p-3 z-40 rounded-3xl font-inter flex flex-col duration-300 ease-in-out transition-all
      ${isOpen ? "translate-x-0" : "translate-x-[250%]"}
      `}
      >
        <div className={"flex justify-between mb-2"}>
          <div className={"flex gap-x-2 items-center"}>
            <h6 className={"font-bold text-base"}>Package Journey</h6>
          </div>

          <button className={"p-2.5 rounded-full"} onClick={handleClose}>
            <CloseSvg h={"17"} color={COLORS.primary} />
          </button>
        </div>

        <div className={"overflow-y-scroll scrollbar-hide"}>
          {/*    package journey   */}
          <PackageJourney showFull={true} />
        </div>
      </div>
    </>
  );
};

export default JourneyPane;
