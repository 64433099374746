import {
	Footer,
} from "../components/dashboard";

const RiderPrivacyPolicy = () => {

	function Heading({ heading, number }) {
		return (
			<div className="flex mt-4">
				<p className="text-xl font-bold">
					{number}
				</p>
				<p className="text-xl font-bold mr-2">
					.
				</p>
				<p className="text-xl font-bold ">
					{heading}
				</p>
			</div>
		);
	}

	function Paragraph({ text }) {
		return (
			<div>
				<p className="text-large mt-4">
					{text}
				</p>
			</div>
		);
	}

	function SubHeading({ number, subheading, text }) {
		return (
			<div className="flex flex-col mt-4">
				<div className="flex">
					<p className="text-large">
						{number}
					</p>
					<p className="text-large font-bold mr-2">
						.
					</p>
					<p className="text-large font-bold ">
						{subheading}
					</p>
				</div>
				<p className="text-large">
					{text}
				</p>
			</div>
		);
	}

	return (
		<div className={"min-h-screen flex flex-col bg-white_bg "}>
			<div className={"main flex-grow mx-5 lg:mx-12 mb-12"}>
				<h5
					className={
						"mt-24 flex justify-center mb-6 md:mb-10 font-times text-[25px] md:text-[40px] font-[600] leading-8 md:leading-[48px] text-grey_800 text-center md:text-left"
					}
				>
					Privacy Policy For Pickup Mtaani Rider App
				</h5>


				<div className="flex">
					<p className="mr-3">
						Effective Date:
					</p>
					<p className="font-bold">
						07/11/2023
					</p>
				</div>

				<Heading heading={'Introduction'} number={'1'} />

				<Paragraph text={'Welcome to Pickup Mtaani Rider App ("we," "our," or "us"), owned and operated by Pickup Mtaani Kenya Ltd ("Pickup Mtaani"). We are committed to protecting your privacy and providing you with a secure experience when using our rider app. This Privacy Policy outlines how we, Pickup Mtaani Kenya Ltd, collect, use, disclose, and safeguard your personal information through our rider app. By using Pick-up Mtaani Rider App, you agree to the terms and conditions of this Privacy Policy.'} />

				<Heading heading={'Information We Collect'} number={'2'} />

				<Paragraph text={'For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to Name, Email, Phone Number, Gender, Address,. The information that we request will be retained by us and used as described in this privacy policy. We obtain geolocation data from users of the Pickup Mtaani rider app through background tracking to acquire real-time information regarding the precise location of Pickup Mtaani’s riders. This ensures accurate estimations for both pickups and drop-offs of packages. Additionally, it aids Pickup Mtaani in upholding rider safety, optimizing routes, addressing and reporting any potential delays, and guaranteeing punctual deliveries, thereby enhancing overall service efficiency. Our stringent data privacy policy is applicable to all Pickup Mtaani personnel, and your geolocation data is securely stored and exclusively accessible to authorized team members solely for operational purposes. We remain dedicated to respecting your privacy and affording you complete control over your location settings within the Pickup Mtaani rider app. The app does use third-party services that may collect information used to identify you. Link to the privacy policy of third-party service providers used by the app'} />

				<ul className="flex justify-center lg:justify-start lg:ml-12 mt-4">
					<li className="list-disc">
						<a href="https://www.google.com/policies/privacy/" className="text-green-400"> Google Play Services  </a>
					</li>
				</ul>


				<Paragraph text={'We, Pickup Mtaani Kenya Ltd, may collect the following types of information from you when you use our rider app:'} />

				<SubHeading number={'2.1'} subheading={'Personal Information'} text=
					{'We may collect personal information, including but not limited to your name, email address, phone number, and payment information.'} />

				<SubHeading number={'2.2'} subheading={'Location Information'} text=
					{'We may collect your precise location information, such as GPS data, to provide our rider services and improve the user experience.'} />

				<SubHeading number={'2.3'} subheading={'Device Information'} text=
					{'We may collect information about the device you use to access our app, such as device type, operating system, and unique device identifiers.'} />

				<SubHeading number={'2.4'} subheading={'Usage Information'} text=
					{'We may collect data related to your usage of our app, including trip history, routes taken, and other app interactions.'} />

				<SubHeading number={'2.5'} subheading={'Log Information'} text=
					{'Our servers may automatically log certain information, such as your IP address, device information, and usage data when you use our app.'} />

				<Heading heading={'How We Use Your Information'} number={'3'} />

				<Paragraph text={'We, Pickup Mtaani Kenya Ltd, may use the information we collect for the following purposes:'} />

				<SubHeading number={'3.1'} subheading={'Providing Services'} text={'To facilitate and process your business transactions and provide shelf management services.'} />

				<SubHeading number={'3.2'} subheading={'Improving Our App'} text={'To enhance the quality and features of our Rent A Shelf Manager app, including analyzing usage patterns and conducting research.'} />

				<SubHeading number={'3.3'} subheading={'Customer Support'} text={'o respond to your inquiries and provide customer support related to our app and services.'} />

				<SubHeading number={'3.4'} subheading={'Legal Compliance'} text={'To comply with legal and regulatory requirements.'} />

				<Heading heading={'Data Sharing and Disclosure'} number={'4'} />

				<Paragraph text={'We, Pickup Mtaani Kenya Ltd, may share your information with third parties in the following circumstances:'} />

				<SubHeading number={'4.1'} subheading={'Service Providers'} text={'We may share your information with third-party service providers who assist us in operating our app, processing payments, and providing customer support.'} />

				<SubHeading number={'4.2'} subheading={'Legal Requirements'} text={'We may disclose your information if required by law or in response to valid requests from law enforcement or governmental authorities.'} />

				<SubHeading number={'4.3'} subheading={'Business Transfers'} text={' In the event of a merger, acquisition, or sale of all or part of our assets, your information may be transferred to the acquiring entity'} />

				<Heading heading={'Age Restriction'} number={'5'} />

				<Paragraph text={'These Services do not address anyone under the age of 18.'} />

				<Heading heading={'Security'} number={'6'} />

				<Heading heading={'Your Choices'} number={'7'} />

				<Paragraph text={'You can control your business and transaction information in the following ways:'} />

				<SubHeading number={'7.1'} subheading={'Account Information'} text={'You can update and correct your business information within the app.'} />

				<SubHeading number={'7.2'} subheading={'Location Information'} text={'You can enable or disable location services in your device settings.'} />

				<Heading heading={'Changes to this Privacy Policy'} number={'8'} />

				<Paragraph text={'We may update this Privacy Policy from time to time. If we make significant changes, we will notify you through the app or by other means. Your continued use of the app after such changes constitutes your acceptance of the updated Privacy Policy.'} />

				<Heading heading={'Contact Us'} number={'9'} />

				<Paragraph text={'If you have any questions or concerns about this Privacy Policy or our data practices, please contact Pickup Mtaani Kenya Ltd at pickupmtaani@gmail.com'} />

			</div>
			<Footer />
		</div >
	);

};

export default RiderPrivacyPolicy;
