import React from "react";

const MoneySvg = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.49984 6.41664V8.74998M10.4998 5.24998V7.58331M9.9165 2.33331C11.3449 2.33331 12.1175 2.55192 12.5019 2.72149C12.5531 2.74407 12.5787 2.75536 12.6526 2.82586C12.6969 2.86812 12.7777 2.99213 12.7985 3.0497C12.8332 3.14574 12.8332 3.19824 12.8332 3.30325V9.57315C12.8332 10.1033 12.8332 10.3683 12.7537 10.5046C12.6728 10.6431 12.5948 10.7076 12.4435 10.7608C12.2947 10.8132 11.9943 10.7555 11.3936 10.6401C10.9731 10.5593 10.4745 10.5 9.9165 10.5C8.1665 10.5 6.4165 11.6666 4.08317 11.6666C2.65476 11.6666 1.88218 11.448 1.49777 11.2785C1.44658 11.2559 1.42098 11.2446 1.3471 11.1741C1.30282 11.1318 1.22199 11.0078 1.20119 10.9503C1.1665 10.8542 1.1665 10.8017 1.1665 10.6967L1.1665 4.42681C1.1665 3.8967 1.1665 3.63164 1.246 3.4954C1.32686 3.35681 1.40485 3.29238 1.55621 3.23912C1.705 3.18676 2.00536 3.24447 2.60607 3.35989C3.02653 3.44068 3.5252 3.49998 4.08317 3.49998C5.83317 3.49998 7.58317 2.33331 9.9165 2.33331ZM8.45817 6.99998C8.45817 7.80539 7.80525 8.45831 6.99984 8.45831C6.19442 8.45831 5.5415 7.80539 5.5415 6.99998C5.5415 6.19456 6.19442 5.54165 6.99984 5.54165C7.80525 5.54165 8.45817 6.19456 8.45817 6.99998Z"
        stroke="#667085"
        strokeWidth="1.17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MoneySvg;
