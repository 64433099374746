import React from "react";

const ReceiptSvg = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.3335 4.55C2.3335 3.56991 2.3335 3.07986 2.52423 2.70552C2.69201 2.37623 2.95973 2.10852 3.28901 1.94074C3.66336 1.75 4.1534 1.75 5.1335 1.75H8.86683C9.84692 1.75 10.337 1.75 10.7113 1.94074C11.0406 2.10852 11.3083 2.37623 11.4761 2.70552C11.6668 3.07986 11.6668 3.56991 11.6668 4.55V12.25L10.0627 11.0833L8.60433 12.25L7.00016 11.0833L5.396 12.25L3.93766 11.0833L2.3335 12.25V4.55Z"
        stroke="#667085"
        strokeWidth="1.17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReceiptSvg;
