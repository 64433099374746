import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TrackingServices from "../services/TrackingServices";
import Spinner from "../components/general/Spinner";
import {
	Footer,
} from "../components/dashboard";
import { toast } from "react-toastify";

// The details of each agent
const Agent = () => {
	// get location from url queries
	const params = useParams();
	const [agent, setAgent] = useState([]);
	const [loading, setLoading] = useState(false);

	const getCurrentAgent = async () => {
		try {
			setLoading(true);
			const { agent } = await TrackingServices.fetchAgent(params?.id);
			setAgent(agent);
			setLoading(false);
		}

		catch (error) {
			toast.error(`Error! `.concat(error.response.data?.message || error.message), {
				position: "bottom-center",
			});
			setLoading(false);
		};
	};

	useEffect(() => {
		getCurrentAgent();
	}, [params?.id]);

	return (
		<div className={"min-h-screen flex flex-col bg-white_bg "}>
			{/*  container  */}
			<div className="main flex-grow ">
				<div className={"md:w-11/12 xl:w-6/12 mx-auto p-5 gap-4 flex-1"}>

					<h5
						className={
							"mt-24 flex justify-center mb-6 md:mb-10 font-times text-[25px] md:text-[40px] font-[600] leading-8 md:leading-[48px] text-grey_800 text-center md:text-left"
						}
					>
						{agent?.business_name}{" "}
					</h5>

					{
						agent?.images ?
							<div className={"bg-slate-50 flex justify-center rounded-xl"}>
								<img
									src={agent?.images}
									className={"h-[500px] w-full lg:w-1/2 object-contain"}
								/>
							</div>
							:
							<div>
							</div>
					}
					<div className={"bg-white p-5 mt-5 rounded-xl flex flex-col gap-4"}>
						<div className={"flex flex-col gap-4"}>
							<h5 className={"font-bold text-base lg:text-lg"}>Name: </h5>
							<h5 className={"text-base lg:text-lg"}>{agent?.business_name} </h5>
						</div>

						<div className={"flex flex-col gap-4"}>
							<h5 className={"font-bold text-base lg:text-lg"}>Description: </h5>
							<h5 className={"text-base lg:text-lg"}>{agent?.agent_description} </h5>
						</div>


						<div className={"flex flex-col gap-4"}>
							<h5 className={"font-bold text-base lg:text-lg"}>Location: </h5>
							<h5 className={"text-base lg:text-lg"}>{agent?.agent_locations?.name} </h5>
						</div>

						<div className={"flex flex-col gap-4"}>
							<h5 className={"font-bold text-base lg:text-lg"}>Status: </h5>
							<h5
								className={`font-medium lg:text-lg text-base ${!agent?.isOpen ? "text-green-600" : "text-gray-500"
									}`}
							>
								{!agent?.isOpen ? "Open" : "Closed"}
							</h5>
						</div>

						<div className={"flex flex-col gap-3 mt-5"}>
							<h5 className={"font-bold text-lg"}>Working hours </h5>

							<div className={"flex gap-4 "}>
								<h5 className={"font-medium text-base opacity-70 lg:text-lg"}>
									Monday - Friday:{" "}
								</h5>

								<p className={"lg:text-lg"}>
									{agent?.opening_hours} - {agent?.closing_hours}
								</p>
							</div>
						</div>

						<div className={"flex flex-col gap-3 mt-5"}>
							<h5 className={"font-bold text-lg"}>Services Offered: </h5>

							<p className={"opacity-70 lg:text-lg"}>Package sending and recieving</p>
						</div>

						<div className={"flex flex-col gap-3 mt-5"}>
							<h5 className={"font-bold text-lg"}>Collection Period: </h5>

							<p className={"opacity-70 lg:text-lg"}>
								You have up to 3 days to collect your package at this agent
							</p>
						</div>
					</div>
				</div>

				{loading && <Spinner />}
			</div>
			<Footer />
		</div>
	);
};

export default Agent;
