import {
	Footer,
} from "../components/dashboard";

const VendorPrivacyPolicy = () => {

	const listitems = [
		{ 'text': 'Money', 'number': 1 },
		{ 'text': 'Contraband', 'number': 2 },
		{ 'text': 'Food, flowers, and groceries', 'number': 3 },
		{ 'text': 'explosives, ammunitions and flammable items', 'number': 4 },
		{ 'text': 'electronics i.e phones, laptops,tv,microwave etc', 'number': 5 },
		{ 'text': 'pets, ashes and human remains', 'number': 6 },
		{ 'text': 'Some fragile products such as gift boxes, some glass or breakable items', 'number': 7 },
		{ 'text': 'Bulky and/or heavy packages suitcases or big boxed items, big pillows etc', 'number': 8 },
	];

	const guidelines = [{ 'text': 'Package should be properly sealed.', 'number': 1 },
	{ 'text': 'Package should be properly and visibly labeled.', 'number': 2 },
	{ 'text': 'Package SHOULD NOT be in transparent packaging', 'number': 3 }
	];

	function Heading({ heading, number }) {
		return (
			<div className="flex mt-4">
				<p className="text-xl font-bold">
					{number}
				</p>
				<p className="text-xl font-bold mr-2">
					.
				</p>
				<p className="text-xl font-bold ">
					{heading}
				</p>
			</div>
		);
	}

	function Paragraph({ text }) {
		return (
			<div>
				<p className="text-large mt-4">
					{text}
				</p>
			</div>
		);
	}

	function SubHeading({ number, subheading, text }) {
		return (
			<div className="flex flex-col mt-4">
				<div className="flex">
					<p className="text-large">
						{number}
					</p>
					<p className="text-large font-bold mr-2">
						.
					</p>
					<p className="text-large font-bold ">
						{subheading}
					</p>
				</div>
				<p className="text-large">
					{text}
				</p>
			</div>
		);
	}

	function ListItems({ list }) {
		return (
			<div>
				{
					list.map(result =>
						<div>
							<ul className="list-disc ">
								<li key={result.number} className="">
									{result.text}
								</li>
							</ul>
						</div>
					)
				}
			</div>
		);
	}

	return (
		<div className={"min-h-screen flex flex-col bg-white_bg "}>
			<div className={"main flex-grow mx-5 lg:mx-12 mb-12"}>
				<h5
					className={
						"mt-24 flex justify-center mb-6 md:mb-10 font-times text-[25px] md:text-[40px] font-[600] leading-8 md:leading-[48px] text-grey_800 text-center md:text-left"
					}
				>
					Privacy Policy For Pickup Mtaani Vendor App
				</h5>


				{/* <div className="flex"> */}
				{/* 	<p className="mr-3"> */}
				{/* 		Effective Date: */}
				{/* 	</p> */}
				{/* 	<p className="font-bold"> */}
				{/* 		07/11/2023 */}
				{/* 	</p> */}
				{/* </div> */}

				<Heading heading={'CBD offices; operational hours and locations'} number={'1'} />

				<Paragraph text={'Our town offices Philadelphia house next to Afya center 3rd floor wing A at Pick up mtaani and Star Mall 3rd floor shop C14 at Pick up mtaani are operational Mon-Fri 8:30am-7pm and Saturday 9:30am-6pm. Closed on Sunday. Public holidays 9:30am-4pm.'} />

				<Heading heading={'Items we do not deliver'} number={'2'} />

				<ListItems list={listitems} />

				<Heading heading={"Note"} number={2} />

				<Paragraph text={"Cosmetics products made of glass packaging should have bubble wrap or any form of protective layers."} />

				<Paragraph text={"We reserve the right to refuse delivery if the package is among the categories of items we do not deliver."} />

				<Heading heading={"Packaging guidelines"} number={3} />

				<ListItems list={guidelines} />

				<Paragraph text={"We reserve the right to refuse delivery if the package doesn’t meet our packaging and labelling guidelines"} />

				<Heading heading={"UNDERSTANDING OUR SCHEDULES*"} number={5} />

				<Paragraph text={"We offer same day delivery to our agents within Nairobi from CBD through 3 delivery schedules 9:30am,1:30pm and 5pm. Packages dropped after 5pm delivery is the following day."} />

				<Paragraph text={"We do have 3 delivery shifts in a day From *CBD*"} />

				<ListItems list={[{ 'text': 'First shift 9:30am to 1:30pm', 'number': '3' }, { 'text': 'Second shift 1:30pm to 5pm', 'number': 2 },
				{ 'text': 'Third shift 5pm to 8pm', 'number': 3 }]} />

		<Heading heading={"When sending from *MTAANI* the schedule is as below"} number={6}/>

				<Paragraph text={"Any parcels dropped before 10am delivery is better 2pm to 4pm, Since it will be picked between 10am to 1pm. Any parcel dropped after 10am but before 2pm delivery is between 5pm to 8pm , Since it will be picked between 2pm to 4pm.After 2pm next day delivery. If dropped at mtaani agent between 2-5pm the collection is done in the evening for next day delivery between 10am-1pm. If dropped at a mtaani agent after 5pm delivery will be the following day between 2-5pm"} />

		<Heading heading={"Package collection period and Return policy"} number={7}/>

				<Paragraph text={"All packages sent to Pickup Points outside Nairobi CBD will stay at the collection point for 3 days."} />

				<Paragraph text={"Agent closure within collection period stipulated does not pass liability of any kind on Pickup Mtaani. Customer finding an agent closed are advised to visit again within the 3days period. We are however liable to home delivery if the client has visited the location and notified us of agent closure for the 3 collection days."} />

				<Paragraph text={"Pickup mtaani will only assume responsibility of delivering via home delivery if the agent remains closed for a continued period of 3days from the date the customer received their collection notification."} />


				<Paragraph text={"After 3 days packages are returned to the office in CBD and senders notified via SMS. Pickup mtaani does not guarantee that the return message will be delivered therefore urge customers to claim any unfulfilled deliveries within 10days from the date"} />


				<Paragraph text={"The senders will have 7 days to collect the returned packages. All uncollected returns will be disposed off after 30days."} />

				<Paragraph text={"Packages that have exceeded 10days from the date of dispatch will accrue a daily fee of ksh.100, the fine is to be settled before collection of the package."} />

				<Paragraph text={"Pickup Mtaani will not be held liable for any uncollected return package."} />

		<Heading heading={"Claim policy"} number={8}/>

				<Paragraph text={"No action against the company in respect of any claims arising out if carriage of goods shall be made tangible unless written notice of such claim is given to the company within a period of 10days from the date of delivery."} />

				<Paragraph text={"Pick-up mtaani shall not be held liable for any packages that is claimed after 10 days of being sent."} />

				<Paragraph text={"In any event the amount payable for the one claim for loss or damage shall not excced kshs. 2000"} />

		<Heading heading={"Damaged/missing delivery refund procedure and policy."} number={9}/>

				<Paragraph text={"If you receive your package damaged you are required to report to our customer service team via DM, Whatsapp or Call while still at the Pickup point/agent location or with rider. Share an image of the damaged product."} />

				<Paragraph text={"Tampered packaging is to be reported immediately to our customer service team via DM, whatsapp or Call while still at the agent/with rider during collection. Share an image of how you’ve received the package."} />

				<Paragraph text={"Pick up mtaani refrains from delivery of fragile products, delivery is at owners risk. The company shall not be held liable for any damage claim if no protective packaging is used."} />

				<Paragraph text={"We reserve the right to facilitate a 7-14days investigation and refund process for any missing/damaged package liable for a refund."} />

				<Paragraph text={"To process any refund the following documents are to be presented;"} />

				<ListItems list={[{ 'text': 'Mpesa statement of the purchase transaction from both the seller and buyer', 'number': 1 },
				{
					'text': 'Image representation of the product on the sellers Instagram/Facebook and social media pageor website',
					'number': 2
				}
				]} />

				<Paragraph text={"The company reserves a right to decline any claim if the above documents are not presented or present any evidence of alterations."} />


				<Heading heading={"What do I need to collect your package? You may ask"} number={10} />

				<Paragraph text={"Receivers are required to present either the notification message received from pick up mtaani or their national Identification card or passport."} />

				<Paragraph text={"Agent location and operational hours are available on the agent profile tab on PICKUPMTAANI.COM. Track the status of your package using your package code. (PMT-TY-6728)"} />


				<Paragraph text={"The company may refuse to hand over the consignment if in doubt of the identity of the cosignee, cosignee will be required to produce sufficient acceptable identity and any acceptable authority if necessary."} />

				<Heading heading={"Home/Office delivery policies"} number={11} />

				<Paragraph text={"We offer same day doorstep delivery for packages dropped at our town offices before 5pm. Past this time delivery is done the following day."} />

				<Paragraph text={"Pick up mtaani offers pay on delivery services, we supervise and receive payments on behalf of the vendor. Payment transfer to the vendor will be less service fees and transaction fees."} />

				<Paragraph text={"Cancelled deliveries before facilitated by the rider will not be charged."} />

				<Paragraph text={"Cancelation at the point of delivery is chargeable. Returns at the point of delivery are free."} />

				<Paragraph text={"Customers to pay via Mpesa only our riders will not collect hard cash."} />

				<Paragraph text={"If you have any questions please call 0110 151 006"} />

				<Heading heading={"Process of sending doorstep delivery from MTAANI"} number={12} />

				<Paragraph text={"Download the App"} />

				<Paragraph text={"Record the delivery and drop at a mtaani agent."} />

		<Heading heading={"UNDERSTANDING OUR SCHEDULES FROM MTAANI"} number={13}/>

				<Paragraph text={"Any parcels dropped before 10am delivery is better 2pm to 4pm Since it will be picked between 10am to 1pm"} />

				<Paragraph text={"Any parcel dropped after 10am but before 2pm delivery is between 5pm to 8pm Since it will be picked between 2pm to 4pm"} />

				<Paragraph text={"If dropped at mtaani agent between 2-5pm the collection is done in the evening for next day delivery between 10am-1pm"} />

				<Paragraph text={"If dropped at a mtaani agent after 5pm delivery will be the following day between 2-5pm"} />

		<Heading heading={"COLLECT MY CASH"} number={14}/>

				<Paragraph text={"Customers will be prompted by our Agents and Riders via the Pick up Mtaanj App to pay through Mpesa."} />

				<Paragraph text={"Payment will only happen at the point of collection by the customer."} />

				<Paragraph text={"Our riders and agents will not collect hard cash."} />

				<Paragraph text={"This option is not to be chosen for any payment that will be handled outside the system."} />

				<Paragraph text={"Once paid the money will available for withdrawal from pochi at your convenient time"} />

				<Paragraph text={"Transaction charges are as follows"} />

				<ListItems list={[{ 'text': '101 Kes - 1500 kes \\10kes', 'number': 1 },
				{ 'text': '1501 kes -5000 kes \\ 15 kes', 'number': 2 },
				{ 'text': '5001 kes – 20000 kes\\ 20 kes', 'number': 3 }
				]} />

				<Heading heading={"PARCELS"} number={15} />

				<Paragraph text={"The delivery fees charged on the App includes the service fee for dropping your package at the courier of your choice"} />


				<Paragraph text={"Parcel fee or courier fees are determined outside the App once the package is dropped of at the courier"} />

				<Heading heading={"Payment"} number={16} />


				<Paragraph text={"Any manual payment made is to be utilised within 24hrs, after this period the payment is not valid and non refundable"} />


				<Paragraph text={"Incase of any payment issues reach out to our contact lines +254110151006/ 0780044777 within the 24hr period"} />

			</div>
			<Footer />
		</div >
	);

};

export default VendorPrivacyPolicy;
