import React from "react";

const BuildingSvg = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 12.25V9.1C8.75 8.7733 8.75 8.60995 8.68642 8.48517C8.63049 8.37541 8.54126 8.28617 8.43149 8.23025C8.30671 8.16667 8.14336 8.16667 7.81667 8.16667H6.18333C5.85664 8.16667 5.69329 8.16667 5.56851 8.23025C5.45874 8.28617 5.36951 8.37541 5.31358 8.48517C5.25 8.60995 5.25 8.7733 5.25 9.1V12.25M1.75 4.08333C1.75 5.04983 2.5335 5.83333 3.5 5.83333C4.4665 5.83333 5.25 5.04983 5.25 4.08333C5.25 5.04983 6.0335 5.83333 7 5.83333C7.9665 5.83333 8.75 5.04983 8.75 4.08333C8.75 5.04983 9.5335 5.83333 10.5 5.83333C11.4665 5.83333 12.25 5.04983 12.25 4.08333M3.61667 12.25H10.3833C11.0367 12.25 11.3634 12.25 11.613 12.1228C11.8325 12.011 12.011 11.8325 12.1228 11.613C12.25 11.3634 12.25 11.0367 12.25 10.3833V3.61667C12.25 2.96327 12.25 2.63657 12.1228 2.38701C12.011 2.16749 11.8325 1.98901 11.613 1.87716C11.3634 1.75 11.0367 1.75 10.3833 1.75H3.61667C2.96327 1.75 2.63657 1.75 2.38701 1.87716C2.16749 1.98901 1.98901 2.16749 1.87716 2.38701C1.75 2.63657 1.75 2.96327 1.75 3.61667V10.3833C1.75 11.0367 1.75 11.3634 1.87716 11.613C1.98901 11.8325 2.16749 12.011 2.38701 12.1228C2.63657 12.25 2.96327 12.25 3.61667 12.25Z"
        stroke="#667085"
        strokeWidth="1.17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BuildingSvg;
