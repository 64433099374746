import React, { useState } from "react";
import { MapPinSvg, RightArrowSVG, SearchSVG } from "../../assets/svg";
import { COLORS } from "../../assets/Theme";
import { useNavigate } from "react-router-dom";

const Carousel = () => {
  const [state, setState] = useState("");
  const navigate = useNavigate();

  const handleTrack = () => {
    // check if tracking number added
    if (!state || state === "") {
      window.alert("Tracking number required");
      return;
    }

    const trackingNumber = state?.split("-");
    //check tracking format
    if (trackingNumber?.length < 4) {
      window.alert(
        "Invalid tracking number. Please enter the correct tracking number"
      );
      return;
    }
    navigate(`/track-package?${state}`);
  };

  return (
    <div className={" py-3 md:py-6 mt-16 "}>
      <LgView
        state={state}
        handleChange={(e) => setState(e.target?.value)}
        handleTrack={handleTrack}
      />
      <SmView
        state={state}
        handleChange={(e) => setState(e.target?.value)}
        handleTrack={handleTrack}
      />
    </div>
  );
};

export default Carousel;

const SmView = ({ state, handleChange, handleTrack }) => (
  <div className={"h-[50vh] relative md:hidden "}>
    <div
      className={
        "h-[50vh] w-full bg-primary_300 overflow-hidden flex md:hidden  items-end justify-end rounded-[53px]"
      }
    >
      <img
        src={require("../../assets/bg-graphics.png")}
        className={"justify-self-end h-[165px] w-3/4 object-cover"}
        alt={"img"}
      />

      <div
        className={
          "absolute z-30 top-0 left-0 right-0 bottom-0 flex items-center px-6"
        }
      >
        <div className={"w-3/5"}>
          <h5
            className={
              "text-[25px] text-grey_900 leading-8 font-times font-[700] mb-2"
            }
          >
            Manage All Your Deliveries Under One Roof
          </h5>

          <p className={"font-lato text-sm font-[400]"}>
            Grow your Online Business all over Kenya with our Affordable
            Delivery Services
          </p>

          <div className="flex flex-1 my-5 gap-3">
            <a
              href={"https://apps.apple.com/ke/app/pickup-mtaani/id6448919500"}
              className={"h-9 md:h-10 object-contain"}
            >
              <img
                src={require("../../assets/app-store.jpg")}
                alt="ios-img"
                className={" rounded-md overflow-hidden h-full"}
              />
            </a>

            <a
              href={
                "https://play.google.com/store/apps/details?id=com.pum_vendor"
              }
              className={"h-9 md:h-10 object-contain"}
            >
              <img
                src={require("../../assets/playstore.png")}
                alt="apk-img"
                className={" rounded-md h-full"}
              />
            </a>
          </div>
        </div>
      </div>
      <img
        src={require("../../assets/mockup.png")}
        className={"absolute -right-[150px] -bottom-8 w-[330px] object-contain"}
        alt={"img"}
      />
    </div>

    <div
      className={
        " absolute z-50 -bottom-3.5 right-0 left-0 flex justify-center  "
      }
    >
      <div
        className={
          "bg-white z-30 shadow-lg flex-[0.85] h-[42px] flex items-center pl-2 rounded-xl overflow-hidden mt-12 lg:w-12/12 w-full"
        }
      >
        {/*  icon  */}
        <MapPinSvg />

        <div className={"h-4 rounded-full bg-primary_300 w-[1px] mx-2"} />

        <input
          className={" h-full focus:outline-none flex-1 text-[13px]"}
          placeholder={"Enter tracking code"}
          value={state}
          onChange={handleChange}
        />

        <button
          className={
            "gap-2 flex items-center justify-center bg-grey_900 text-primary_300 px-3 h-full font-[600]"
          }
          onClick={handleTrack}
        >
          <SearchSVG color={COLORS.primary} />
        </button>
      </div>
    </div>
  </div>
);

const LgView = ({ state, handleChange, handleTrack }) => (
  <div
    className={
      "h-[85vh]  max-h-[680px] bg-primary_300 overflow-hidden hidden md:flex items-center rounded-[78px]"
    }
  >
    {/*  text     */}
    <div className={"w-1/2 px-20"}>
      <h5
        className={
          "text-[40px] text-grey_900 leading-[50px] font-times font-[700] mb-4"
        }
      >
        Manage All Your Deliveries Under One Roof
      </h5>

      <p className={"font-lato text-lg font-[600]"}>
        Grow your Online Business all over Kenya with our Affordable Delivery
        Services
      </p>

      <div className="flex flex-1 my-5 gap-7">
        <a
          href={"https://apps.apple.com/ke/app/pickup-mtaani/id6448919500"}
          className={"h-9 md:h-10 object-contain"}
        >
          <img
            src={require("../../assets/app-store.jpg")}
            alt="ios-img"
            className={" rounded-md overflow-hidden h-full"}
          />
        </a>

        <a
          href={"https://play.google.com/store/apps/details?id=com.pum_vendor"}
          className={"h-9 md:h-10 object-contain"}
        >
          <img
            src={require("../../assets/playstore.png")}
            alt="apk-img"
            className={" rounded-md h-full"}
          />
        </a>
      </div>

      <div
        className={
          "bg-white h-[42px] flex items-center pl-2 rounded-xl overflow-hidden mt-12 lg:w-12/12 w-full"
        }
      >
        {/*  icon  */}
        <MapPinSvg />

        <div className={"h-4 rounded-full bg-primary_300 w-[1px] mx-2"} />

        <input
          className={"flex-1 h-full focus:outline-none"}
          placeholder={"Enter tracking code"}
          onChange={handleChange}
          value={state}
        />

        <button
          className={
            "gap-2 flex items-center justify-center bg-grey_900 text-primary_300 px-3 h-full font-[600]"
          }
          onClick={handleTrack}
        >
          <span className={"mb-0.5"}>TrackPackage</span> <RightArrowSVG />
        </button>
      </div>
    </div>

    {/*    images  */}
    <div className={"flex-1 flex justify-end pr-5 relative "}>
      <img
        src={require("../../assets/bg-graphics.png")}
        className={"justify-self-end w-[395px] object-contain"}
      />

      <img
        src={require("../../assets/mockup.png")}
        className={"absolute top-0 left-14 w-[530px] object-contain"}
      />
    </div>
  </div>
);
