import React from "react";

const VerifiedSvg = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.782 6.49901C11.7822 7.66418 11.4369 8.80324 10.7897 9.77215C10.1425 10.741 9.22251 11.4963 8.14608 11.9423C7.06966 12.3883 5.88515 12.5051 4.74234 12.2779C3.59953 12.0507 2.54976 11.4898 1.72579 10.6659C0.901819 9.8421 0.340656 8.79242 0.11327 7.64965C-0.114117 6.50688 0.0024862 5.32235 0.448333 4.24585C0.894179 3.16935 1.64924 2.24925 2.61803 1.60189C3.58683 0.954535 4.72583 0.609009 5.891 0.609009C6.66457 0.608878 7.43059 0.76113 8.14532 1.05707C8.86004 1.35301 9.50947 1.78685 10.0565 2.3338C10.6036 2.88075 11.0375 3.5301 11.3336 4.24477C11.6296 4.95944 11.782 5.72544 11.782 6.49901ZM5.21 9.61801L9.58 5.24801C9.65114 5.17676 9.6911 5.08019 9.6911 4.97951C9.6911 4.87882 9.65114 4.78226 9.58 4.71101L9.038 4.17301C8.96676 4.10187 8.87019 4.06191 8.7695 4.06191C8.66882 4.06191 8.57225 4.10187 8.501 4.17301L4.938 7.73701L3.277 6.07301C3.20576 6.00187 3.10919 5.96191 3.0085 5.96191C2.90782 5.96191 2.81125 6.00187 2.74 6.07301L2.203 6.61001C2.13186 6.68126 2.0919 6.77782 2.0919 6.87851C2.0919 6.97919 2.13186 7.07576 2.203 7.14701L4.673 9.61701C4.74412 9.68828 4.84061 9.72842 4.9413 9.72861C5.04198 9.7288 5.13862 9.68902 5.21 9.61801Z"
        fill="#36BFFA"
      />
    </svg>
  );
};

export default VerifiedSvg;
