import axios from "axios";
import { BASE_URL } from "../Config";

const fetchCustomerCareNum = async () => {
	const response = await axios.get(
    `${BASE_URL}/customercarenumber`
  );
	
  return response?.data;
};

const CustomerCareServices = {
	fetchCustomerCareNum,
};

export default CustomerCareServices;
