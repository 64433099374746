import React, { useEffect, useRef, useState } from "react";
import {
  AgentSvg,
  DoorstepSvg,
  ErrandSvg,
  RentShelfSvg,
  RightArrowSVG,
} from "../../assets/svg";
import { COLORS } from "../../assets/Theme";

const Services = () => {
  const videoRef = useRef();

  const [current, setCurrent] = useState(0);
  const scrollLeft = (nxt) => {
    if (window.innerWidth < 500) {
      document.getElementById("content").scrollLeft -=
        window.innerWidth * 0.9 * Math.abs(nxt - current);
    } else {
      document.getElementById("content").scrollLeft -=
        window.innerWidth * 0.814643 * Math.abs(nxt - current);
    }
  };
  const scrollRight = (nxt) => {
    if (window.innerWidth < 500) {
      document.getElementById("content").scrollLeft +=
        window.innerWidth * 0.9 * Math.abs(nxt - current);
    } else {
      document.getElementById("content").scrollLeft +=
        window.innerWidth * 0.814643 * Math.abs(nxt - current);
    }
  };

  const handleScroll = (next_index) => {
    if (next_index < current) {
      // scroll left
      scrollLeft(next_index);
    } else if (next_index === current) {
      // scroll left
      return;
    } else {
      // scroll right
      scrollRight(next_index);
    }
    setCurrent(next_index);
  };

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "0px",
      threshold: 0.5, // Trigger when 50% of the video is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Play the video when it becomes visible
          let isPlaying =
            videoRef.current.currentTime > 0 &&
            !videoRef.current.paused &&
            !videoRef.current.ended &&
            videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;
          // Play the video when it becomes visible
          if (!isPlaying) {
            videoRef.current.play();
          }
        } else {
          // Pause the video when it's not visible
          videoRef.current.pause();
        }
      });
    }, options);

    observer?.observe(videoRef.current);

    return () => {
      if (videoRef?.current) {
        observer?.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <div className={"mt-8 md:mt-40 xl:mx-16"} id={"features"}>
      {/*    discover     */}
      <div className={"flex flex-col-reverse md:flex-row items-center"}>
        {/*animation*/}
        <div className={"md:w-1/2 flex-shrink-0 mt-8 md:mt-0"}>
          <video
            className={"mx-auto w-11/12 md:w-[80%]"}
            ref={videoRef}
            autoPlay={true}
            loop
            controls={false}
            muted={"muted"}
            src={require("../../assets/videos/services.mp4")}
            // width={"80%"}
            // height={400}
          />
        </div>

        <div className={"px-6 md:px-0"}>
          {/*    span       */}
          <h5
            className={
              "mb-6 md:mb-10 font-times text-[25px] md:text-[40px] font-[600] leading-8 md:leading-[48px] text-grey_800 text-center md:text-left"
            }
          >
            Discover why thousands of small businesses choose Pickup Mtaani to
            grow their online business.{" "}
          </h5>

          <div className={"grid grid-cols-2 gap-y-6 md:gap-y-[52px]"}>
            <StatisticItem
              title={"25k +"}
              subtitle={"small businesses reserved"}
            />
            <StatisticItem
              title={"100k +"}
              subtitle={"Online shoppers served"}
            />
            <StatisticItem title={"1m +"} subtitle={"packages"} />
            <StatisticItem
              title={"500M +"}
              subtitle={"gross merchandise value"}
            />
          </div>
        </div>
      </div>

      {/*    services     */}
      <div
        id={"content "}
        className={
          "mt-4 pb-3 scrollbar-hide pl-5 md:pl-0 md:mt-28 flex md:grid grid-cols-4 overflow-x-scroll md:justify-between gap-x-3"
        }
      >
        <ServiceItem
          icon={<AgentSvg />}
          title={"Discover our Mtaani"}
          desc={
            <p className={"font-inter text-grey_500 font-[400]"}>
              Save on delivery cost and give your customer more convenience with
              our network of <strong>150+</strong> safe parcel collection
              points.
            </p>
          }
        />
        <ServiceItem
          icon={<DoorstepSvg />}
          title={"Doorstep"}
          desc={
            <p className={"font-inter text-grey_500 font-[400]"}>
              Enjoy convenient door to door delivery at an affordable rate with
              our network of experienced riders.
            </p>
          }
        />
        <ServiceItem
          icon={<RentShelfSvg />}
          title={"Rent a shelf"}
          desc={
            <p className={"font-inter text-grey_500 font-[400]"}>
              Our CBD offices will help run your business efficiently with our
              affordable storage service.
            </p>
          }
        />
        <ServiceItem
          icon={<ErrandSvg />}
          title={"Errands"}
          desc={
            <p className={"font-inter text-grey_500 font-[400]"}>
              Save time with our errand services Where we drop your parcels at
              your customer’s preferred SACCO or Courier Provider.
            </p>
          }
        />
      </div>
      <div className={"py-3 flex flex-row justify-end pr-8 md:hidden"}>
        <button>
          <RightArrowSVG color={COLORS.grey_500} />
        </button>
      </div>
    </div>
  );
};

export default Services;

const StatisticItem = ({ title, subtitle }) => (
  <div className={"flex gap-x-3"}>
    <div
      className={"h-11/12  w-[4px] md:w-[5px] rounded-full bg-primary_300"}
    />

    <div>
      <h4
        className={
          "font-lato text-[24px] md:text-[34px] text-grey_900 font-[500] uppercase"
        }
      >
        {title}
      </h4>

      <span
        className={
          "font-lato font-[600] md:font-[500] text-[11px] md:text-base text-grey_600 capitalize"
        }
      >
        {subtitle}
      </span>
    </div>
  </div>
);

const ServiceItem = ({ icon, title, desc }) => (
  <div
    className={
      "flex  w-3/4 md:w-auto flex-shrink-0 gap-2 p-2 md:p-4 border border-grey_200 bg-white rounded-md"
    }
    style={{
      boxShadow: `0px 10px 39px -4px rgba(16, 24, 40, 0.07)`,
    }}
  >
    <div className={"flex-shrink-0"}>{icon}</div>

    <div>
      <h6
        className={
          "text-grey_700 text-sm md:text-base mt-1 font-inter font-[500]"
        }
      >
        {title}
      </h6>
      <p className={"text-sm md:text-base"}>{desc}</p>
    </div>
  </div>
);
