import React, { useRef, useState } from "react";
import { MuteSvg, PauseSvg, PlaySvg, UnMuteSvg } from "../../assets/svg";

const WhoChoseUs = () => {
  const videoRef = useRef();
  const [mode, setMode] = useState(0); // 0 - pause, 1 - playing
  const [soundMode, setSoundMode] = useState(0); // 0 - mute, 1 - unmuted

  return (
    <div
      className={
        "m-4 bg-[#F8F8F8] rounded-[78px] md:p-12 py-8 md:py-16 overflow-y-hidden overflow-x-hidden"
      }
      id={"testimonials"}
    >
      <h5
        className={
          "font-times text-[28px] md:text-[48px] font-[600] text-grey_800 leading-9 md:leading-[52px] xl:w-1/2 mx-auto text-center"
        }
      >
        Meet the people who choose Pickup Mtaani
      </h5>
      <div
        className={
          " flex items-center justify-end md:justify-center bg-slate-50 pb-28 md:pb-0"
        }
      >
        <div className={"relative"}>
          <img
            className={"absolute top-1 -left-44 md:-left-[18vw] "}
            src={require("../../assets/l1.png")}
            alt="l1"
          />

          <img
            className={
              "absolute top-8 top-[80px] md:top-[140px] -left-44 md:-left-[28vw]"
            }
            src={require("../../assets/l2.png")}
            alt="l2"
          />

          <img
            className={
              "absolute top-[170px] md:top-[300px] -left-44 md:-left-[18vw]"
            }
            src={require("../../assets/l3.png")}
            alt="l3"
          />

          <img
            className={
              "absolute top-[250px] md:top-[450px] -left-44 md:-left-[28vw]"
            }
            src={require("../../assets/l4.png")}
            alt="l4"
          />

          <img
            className={
              "absolute top-[350px] md:top-[620px] -left-44 md:-left-[24vw]"
            }
            src={require("../../assets/l5.png")}
            alt="l5"
          />

          <div className={"md:px-20 py-10 md:py-24  flex pr-3 md:pr-0"}>
            <div
              className={
                "relative rounded-[27px] border-[5px] border-white overflow-hidden "
              }
              style={{
                boxShadow:
                  "0px 0px 98.83735656738281px 0px rgba(128, 128, 128, 0.20)",
              }}
            >
              <video
                className={"mx-auto h-[400px] md:h-[627px] w-[180px] md:w-auto"}
                autoPlay={false}
                loop
                controls={false}
                src={require("../../assets/videos/comments.mp4")}
                style={{ objectFit: "cover" }}
                ref={videoRef}
              />

              {mode === 0 && (
                <button
                  className={"absolute top-[42%] p-3 left-[35%]"}
                  onClick={() => {
                    videoRef.current?.play();
                    setMode(1);
                  }}
                >
                  <div className={" md:hidden"}>
                    <PlaySvg h={"44"} w={"44"} />
                  </div>

                  <div className={"hidden md:block"}>
                    <PlaySvg />
                  </div>
                </button>
              )}

              {soundMode === 0 && (
                <button
                  className={"absolute p-3 bottom-1 left-1"}
                  onClick={() => {
                    // videoRef.current?.unmute();
                    videoRef.current.muted = false;
                    setSoundMode(1);
                  }}
                >
                  <MuteSvg />
                </button>
              )}

              {mode === 1 && (
                <button
                  className={"absolute bottom-1 right-1 p-3"}
                  onClick={() => {
                    videoRef.current?.pause();
                    setMode(0);
                  }}
                >
                  <PauseSvg />
                </button>
              )}

              {soundMode === 1 && (
                <button
                  className={"absolute bottom-1 left-1 p-3"}
                  onClick={() => {
                    setSoundMode(0);
                    videoRef.current.muted = true;
                  }}
                >
                  <UnMuteSvg />
                </button>
              )}
            </div>
          </div>

          <img
            className={
              "absolute -bottom-[190px] md:top-1 right-[90px] md:-right-[28vw] z-20"
            }
            src={require("../../assets/r1.png")}
            alt="r1"
          />

          <img
            className={
              "absolute top-[140px] -right-[25vw] z-20 hidden md:block"
            }
            src={require("../../assets/r2.png")}
            alt="r2"
          />

          <img
            className={"absolute top-[450px] -right-2 md:-right-[28vw] z-20"}
            src={require("../../assets/r3.png")}
            alt="r3"
          />

          <img
            className={
              "absolute -bottom-[100px] md:top-[630px] right-44 md:-right-[25vw] z-20"
            }
            src={require("../../assets/r4.png")}
            alt="r4"
          />
        </div>
      </div>
    </div>
  );
};

export default WhoChoseUs;
