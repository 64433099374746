import React, { useEffect, useRef } from "react";

const Delivery = () => {
  const videoRef = useRef();

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "0px",
      threshold: 0.5, // Trigger when 50% of the video is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let isPlaying =
            videoRef.current.currentTime > 0 &&
            !videoRef.current.paused &&
            !videoRef.current.ended &&
            videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;
          // Play the video when it becomes visible
          if (!isPlaying) {
            videoRef.current.play();
          }
        } else {
          // Pause the video when it's not visible
          videoRef.current.pause();
        }
      });
    }, options);

    observer?.observe(videoRef.current);

    return () => {
      if (videoRef?.current) {
        observer?.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <div
      className={
        " mt-0 md:mt-32 md:mx-48 flex flex-col md:flex-row items-center"
      }
    >
      {/*    text     */}
      <div className={"flex flex-col justify-center md:w-1/2 px-10 md:px-0"}>
        <h5
          className={
            "font-times text-[28px] text-center md:text-left md:text-[40px] font-[600] text-grey_800 leading-9 md:leading-[52px]"
          }
        >
          Fast, Reliable, Pay on Delivery.
        </h5>

        <p
          className={
            "font-lato text-grey_600 font-[500] text-sm md:text-lg my-4 md:my-8"
          }
        >
          Enjoy instant withdrawals from your Pickup Mtaani{" "}
          <i className={"font-lato font-[600]"}>pochi</i> when our delivery
          agent collect your payment.
        </p>

        <div className="flex justify-center md:justify-start flex-1 my-5 gap-3">
          <a
            href={"https://apps.apple.com/ke/app/pickup-mtaani/id6448919500"}
            className={"h-9 md:h-10 object-contain"}
          >
            <img
              src={require("../../assets/app-store.jpg")}
              alt="ios-img"
              className={" rounded-md overflow-hidden h-full"}
            />
          </a>

          <a
            href={
              "https://play.google.com/store/apps/details?id=com.pum_vendor"
            }
            className={"h-9 md:h-10 object-contain"}
          >
            <img
              src={require("../../assets/playstore.png")}
              alt="apk-img"
              className={" rounded-md h-full"}
            />
          </a>
        </div>
      </div>

      {/*    video      */}
      <div className={"flex flex-shrink-0 flex-1 justify-center my-8 md:my-0"}>
        <div
          className={"relative overflow-hidden rounded-[33px]"}
          style={{
            boxShadow:
              "0px 0px 98.83999633789062px 0px rgba(128, 128, 128, 0.25)",
          }}
        >
          <img
            src={require("../../assets/Mobile.png")}
            alt="ios-img"
            className={" rounded-md overflow-hidden  object-cover "}
          />

          <div className={"absolute top-[10%] right-0 left-0 bottom-[10%] "}>
            <video
              ref={videoRef}
              className={"mx-auto h-96"}
              autoPlay={true}
              loop
              muted={"muted"}
              controls={false}
              src={require("../../assets/videos/delivery.mp4")}
              style={{ height: "100%", objectFit: "cover" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Delivery;
