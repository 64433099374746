import React from "react";

const MobileSvg = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99984 10.5H7.00567M4.08317 1.16663H9.9165C10.5608 1.16663 11.0832 1.68896 11.0832 2.33329V11.6666C11.0832 12.311 10.5608 12.8333 9.9165 12.8333H4.08317C3.43884 12.8333 2.9165 12.311 2.9165 11.6666V2.33329C2.9165 1.68896 3.43884 1.16663 4.08317 1.16663Z"
        stroke="#667085"
        strokeWidth="1.17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MobileSvg;
