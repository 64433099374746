import axios from "axios";
import {BASE_URL} from "../Config";

/**
 *
 * @param {{name:string, phone:string, message:string, email:string}} body
 * @returns {Promise<*>}
 * @desc sends email
 */
const getAgentsZones = async () => {
  const response = await axios.get(`${BASE_URL}/agents-zones-abc`);

  return response?.data;
};
const getAgentsGrouped = async () => {
  const response = await axios.get(`${BASE_URL}/agents-grouped`);

  return response?.data;
};


const AboutAgents = { getAgentsGrouped, getAgentsZones };

export default AboutAgents;
