import axios from "axios";
import { BASE_URL } from "../Config";

const fetchShelfCustomer = async (id) => {
	const response = await axios.get(
    `${BASE_URL}/shelf/shelf-customer-details/${id}`
  );
	
  return response?.data;
};

const ShelfManagerServices = {
	fetchShelfCustomer,
};

export default ShelfManagerServices;
