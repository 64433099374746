import React, { useEffect, useRef } from "react";

const Tracking = () => {
  const videoRef = useRef();

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "0px",
      threshold: 0.5, // Trigger when 50% of the video is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Play the video when it becomes visible
          let isPlaying =
            videoRef.current.currentTime > 0 &&
            !videoRef.current.paused &&
            !videoRef.current.ended &&
            videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;
          // Play the video when it becomes visible
          if (!isPlaying) {
            videoRef.current.play();
          }
        } else {
          // Pause the video when it's not visible
          videoRef.current.pause();
        }
      });
    }, options);

    observer.observe(videoRef.current);

    return () => {
      if (videoRef?.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <div
      className={
        "py-8 md:py-20 xl:mx-48 flex flex-col-reverse md:flex-row items-center"
      }
      id={"tracking"}
    >
      {/*    video      */}
      <div
        className={"flex  flex-shrink-0 flex-1 justify-start md:justify-center"}
      >
        <div
          className={
            "bg-white border-grey_100 p-2 rounded-[27px] overflow-hidden"
          }
          style={{
            boxShadow:
              "0px 0px 98.83735656738281px 0px rgba(128, 128, 128, 0.20)",
          }}
        >
          <video
            ref={videoRef}
            className={"mx-auto max-h-[625px] "}
            autoPlay={true}
            loop
            controls={false}
            muted={"muted"}
            src={require("../../assets/videos/tracking.mp4")}
            style={{ objectFit: "cover" }}
          />
        </div>
      </div>
      {/*    text     */}
      <div className={"flex flex-col justify-center px-8 md:px-0 md:w-1/2"}>
        <h5
          className={
            "font-times text-[28px] md:text-[40px] text-center md:text-left font-[600] text-grey_800 leading-8 md:leading-[52px]"
          }
        >
          <span className={"hidden md:inline-flex"}>
            Keep your customers at ease with our
          </span>{" "}
          Uber-like delivery tracking and alerts{" "}
          <span className={"hidden md:inline-flex"}> for customers</span>
        </h5>

        <p
          className={
            "font-lato text-grey_600 text-center font-[500] md:hidden text-sm md:text-lg my-4 md:my-8"
          }
        >
          Keep your customers at ease with our Uber-like delivery tracking and
          alerts for customers
        </p>

        <div className="flex justify-center md:justify-start flex-1 my-5 gap-3">
          <a
            href={"https://apps.apple.com/ke/app/pickup-mtaani/id6448919500"}
            className={"h-9 md:h-10 object-contain"}
          >
            <img
              src={require("../../assets/app-store.jpg")}
              alt="ios-img"
              className={" rounded-md overflow-hidden h-full"}
            />
          </a>

          <a
            href={
              "https://play.google.com/store/apps/details?id=com.pum_vendor"
            }
            className={"h-9 md:h-10 object-contain"}
          >
            <img
              src={require("../../assets/playstore.png")}
              alt="apk-img"
              className={" rounded-md h-full"}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Tracking;
