import React, { useMemo } from "react";
import Helper from "../../../utils/Helper";

const PackageLocation = ({ state, rejectReason, isShelf, handleView }) => {
  const deliveryStage = useMemo(
    () => Helper.getCurrentStateDetails(state, rejectReason),
    [state]
  );

  const stages = isShelf ? [1, 2] : [1, 2, 3, 4, 5, 6];

  return (
    <div
      className={
        "p-4 rounded-2xl bg-white border border-gray-200 w-full flex gap-x-3 items-center"
      }
    >
      <div className={"flex-1"}>
        <button onClick={handleView} className={"flex gap-x-3 items-center"}>
          <h5 className={"font-bold text-base"}>
            {deliveryStage?.title ?? "Package stage"}
          </h5>
        </button>

        {/*  stages */}
        <div className={"flex gap-x-2 py-3"}>
          {stages?.map((stage, idx) => (
            <Stage
              key={idx}
              isChecked={Number(stage) <= Number(deliveryStage?.stage ?? 0)}
            />
          ))}
        </div>
        <div className={"flex space-x-1  mt-1 items-center"}>
          <span className={"text-sm"}>{deliveryStage?.desc} </span>
        </div>
      </div>
    </div>
  );
};

export default PackageLocation;

const Stage = ({ isChecked }) => (
  <div
    className={`h-1 rounded-xl ${
      isChecked ? "bg-primary" : "bg-gray-200"
    } flex-1`}
  />
);
