import {
	Footer,
} from "../components/dashboard";
import { useState, useEffect } from 'react';
import ShelfManagerServices from "../services/ShelfManagerServices";
import { useParams } from "react-router-dom";
import Spinner from "../components/general/Spinner";
import { ProceedSVG, RightArrowSVG } from "../assets/svg";
import { COLORS } from "../assets/Theme";
import { toast } from "react-toastify";
import PaymentServices from '../services/PaymentService';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

function ShelfManagerPay() {
	const [shelf_customer_details, setShelfCustomerDetails] = useState([]);
	const [loading, setLoading] = useState(false);
	const [paymentaction, setPaymentAction] = useState(true);
	const [mpesaprompt, setMpesaPrompt] = useState(false);
	const [mpesa_number, setMpesaNumber] = useState('');
	const [paytill, setPayTill] = useState(false);
	const [transaction_code, setTransactionCode] = useState('');

	const params = useParams();
	const MySwal = withReactContent(Swal);


	function fetchCustomerDeets() {
		setLoading(true);
		ShelfManagerServices.fetchShelfCustomer(params?.shelfid).then(
			res => {
				
				setShelfCustomerDetails(res?.customer);
				setLoading(false);
			}
		).catch((error) => {
			setLoading(false);
			toast.error(`Error! `.concat(error.response.data?.message || error.message), {
				position: "bottom-center",
			});
		});
	}

	function PromptPayment() {

		// params to pass: req.body.durations, type=shelf-payments, payment_phone_number, shelfCustomerId. 

		if (!mpesa_number) {
			toast.error(`Please enter phone number`, {
				position: "bottom-center",
			});
			return;
		}

		let date = new Date();
		let month = date.getMonth();
		let year = date.getFullYear();

		setLoading(true);

		PaymentServices.makeShelfPayment(
			{
				durations: [{ "month": month, "year": year }],
				paymentPhoneNumber: mpesa_number.replaceAll(" ", ""),
				shelfCustomerId: params?.shelfid,
			}
		).then(
			res => {
				
				MySwal.fire({
					title: <p> Payment Processed Successfully </p>,
					didOpen: () => {
						// `MySwal` is a subclass of `Swal` with all the same instance & static methods
						// MySwal.showLoading();
					},
				});
				setLoading(false);
				fetchCustomerDeets();
			}
		).catch(error => {
			
			toast.error(`Error! `.concat(error.response.data?.message || error.message), {
				position: "bottom-center",
			});
			setLoading(false);
		});
	}

	function verifyPayment() {
		if (!transaction_code) {
			return;
		}

		let date = new Date();
		let month = date.getMonth();
		let year = date.getFullYear();
		
		setLoading(true);
		
		PaymentServices.verifyShelfPayment(
			{
				"transId": transaction_code,
				"shelfCustomerId": params?.shelfid,
				"durations": [{"month": month, "year": year}]
			}
		).then(res => {
			MySwal.fire({
				title: <p> Payment Processed Successfully </p>,
				didOpen: () => {
					// `MySwal` is a subclass of `Swal` with all the same instance & static methods
					// MySwal.showLoading();
				},
			});
			setLoading(false);
			fetchCustomerDeets();
		}).catch((error) => {
			toast.error(`Error! `.concat(error.response.data?.message || error.message), {
				position: "bottom-center",
			});
			setLoading(false);
		});
	}

	useEffect(() => {
		fetchCustomerDeets();
	}, []);

	return (
		<div className={"min-h-screen flex flex-col bg-white_bg "}>
			<div className="main flex-grow ">
				{
					loading ?
						<Spinner />
						:
						<div>
							{
								shelf_customer_details !== null  ?
									<div>
										<h5
											className={
												"mt-24 flex justify-center mb-6 md:mb-10 font-times text-[25px] md:text-[40px] font-[600] leading-8 md:leading-[48px] text-grey_800 text-center md:text-left"
											}
										>
											Shelf Manager Payment
										</h5>

										<div className="flex flex-col justify-center items-center bg-gray-100 mt-12 mx-12 mb-12 w-auto">
											<p className="flex justify-center text-bold mt-3 mb-3 font-bold text-xl">
Shelf Details 
											</p>

																			<div className="flex justify-around bg-yellow-100 mb-3 h-16 items-center w-full lg:w-1/2">
												<p className="w-32 font-bold">
													Business:
												</p>
												<p className="w-44">
													{shelf_customer_details?.business?.name}
												</p>
											</div>
								
											<div className="flex justify-around bg-yellow-100 mb-3 h-16 items-center w-full lg:w-1/2">
												<p className="w-32 font-bold">
													Shelf Provider:
												</p>
												<p className="w-44">
													{shelf_customer_details?.shelf_provider?.business_name}
												</p>
											</div>

											<div className="flex justify-around bg-yellow-100 mb-3 h-16 items-center w-full lg:w-1/2">
												<p className="w-32 font-bold">
													Provider Location:
												</p>
												<p className="w-44">
													{shelf_customer_details?.shelf_provider?.agent_locations?.name}
												</p>
											</div>


											<div className="flex justify-around bg-yellow-100 mb-3 h-16 items-center w-full lg:w-1/2">
												<p className="w-32 font-bold">
													Status:
												</p>
												{shelf_customer_details?.approved === true ?
													<p className="w-44">
														Approved
													</p>
													:
													<p className="w-44">
														Not Approved
													</p>
												}
											</div>


											<div className="flex justify-around bg-yellow-100 mb-3 h-16 items-center w-full lg:w-1/2">
												<p className="w-32 font-bold">
													Shelf Rate
												</p>
												<p className="w-44">
													{shelf_customer_details?.shelf_payment_rate}
												</p>
											</div>


											<div className="flex justify-around bg-yellow-100 mb-3 h-16 items-center w-full lg:w-1/2">
												<p className="w-32 font-bold">
													Amount to Pay
												</p>
												<p className="w-44">
													{shelf_customer_details?.shelf_payment_rate}
												</p>
											</div>




											{
												paymentaction ?
													<div className="flex flex-col justify-center items-center mt-4 mb-12">
														<ProceedSVG color={COLORS.grey_500} height="40px" width="40px" />

														<button
															className={`flex-0 w-56 mb-6 mt-4 bg-grey_900 text-primary py-3 px-5 text-center font-[600] font-inter rounded-lg ${loading && "opacity-70 cursor-no-drop"
																}`}
															type={"submit"}
															disabled={loading}
															onClick={() => { setMpesaPrompt(!mpesaprompt); setPayTill(false); }}
														>
															{loading ? <Spinner /> : "Prompt Payment"}
														</button>

														{
															mpesaprompt ?
																<div
																	className={
																		"flex w-42 bg-white h-12 lg:h-16 items-center border border-grey_300 rounded-lg pl-3.5 overflow-hidden"}
																>

																	<input
																		className={
																			"flex w-40 h-full text-base font-[400] focus:outline-none text-grey_500"
																		}
																		placeholder={"Enter mpesa number"}
																		value={mpesa_number}
																		onChange={(e) => setMpesaNumber(e.target.value)}
																	/>

																	<button
																		className={
																			"h-full px-3 bg-grey_50 flex items-center justify-center outline-none"
																		}
																		onClick={PromptPayment}
																	>
																		<RightArrowSVG color={"#667085"} />
																	</button>

																</div>

																:
																<div>
																</div>
														}


														<button
															className={`flex-0 w-56 mt-4 bg-grey_900 text-primary py-3 px-5 text-center font-[600] font-inter rounded-lg ${loading && "opacity-70 cursor-no-drop"
																}`}
															type={"submit"}
															disabled={loading}
															onClick={() => { setMpesaPrompt(false); setPayTill(!paytill) }}
														>
															{loading ? <Spinner /> : "Manual Payment"}
														</button>


														{
															paytill ?
																<div className="flex flex-col items-center justify-center mt-6 mb-6">
																	{/* show steps to pay then button to input transaction code */}
																	<ol>
																		<li className="list-decimal mx-12">
																			Go to your M-pesa toolkit and select <span className="font-bold"> Lipa na M-Pesa </span>
																		</li>

																		<li className="list-decimal mx-12">
																			Select <span className="font-bold"> Buy Goods and Services </span>
																		</li>

																		<li className="list-decimal mx-12">
																			You'll be prompted to enter <span className="font-bold"> till number </span>
																		</li>

																		<li className="list-decimal mx-12">
																			Enter <span className="font-bold"> 5684653 </span> as the till number and pay
																		</li>

																		<li className="list-decimal mx-12">
																			Wait for the M-Pesa confirmation SMS
																		</li>

																		<li className="list-decimal mx-12">
																			Copy paste the transaction code (i.e RBM...BV4) <span className="font-bold"> below </span>
																		</li>

																		<li className="list-decimal mx-12">
																			Enter <span className="font-bold"> transaction code </span> below
																		</li>

																	</ol>

																	{/* input transaction code */}
																	<div
																		className={
																			"flex mt-6 bg-white h-full items-center justify-center border border-grey_300 rounded-lg overflow-hidden"}
																	>

																		<input
																			className={
																				"w-56 h-12 lg:h-16 text-base font-[400] focus:outline-none text-grey_500"
																			}
																			placeholder={"Enter transaction code"}
																			value={transaction_code}
																			onChange={(e) => setTransactionCode(e.target.value)}
																		/>

																		<button
																			className={
																				"h-full px-3 bg-grey_50 flex items-center justify-center outline-none"
																			}
																			onClick={verifyPayment}
																		>
																			<RightArrowSVG color={"#667085"} />
																		</button>

																	</div>

																</div>
																:
																<div>

																</div>
														}


													</div>
													:
													<div>

													</div>
											}




										</div>

									</div>
									:
									<div>
										<h5
											className={
												"mt-24 flex justify-center mb-6 md:mb-10 font-times text-[25px] md:text-[40px] font-[600] leading-8 md:leading-[48px] text-grey_800 text-center md:text-left"
											}
										>
											No Shelf User Found
										</h5>
									</div>
							}
						</div>
				}
			</div>
			<Footer />
		</div>
	);
}

export default ShelfManagerPay;
