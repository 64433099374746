import React from "react";

const PersonSvg = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6668 12.25V11.0833C11.6668 10.4645 11.421 9.871 10.9834 9.43342C10.5458 8.99583 9.95233 8.75 9.3335 8.75H4.66683C4.04799 8.75 3.4545 8.99583 3.01691 9.43342C2.57933 9.871 2.3335 10.4645 2.3335 11.0833V12.25M9.3335 4.08333C9.3335 5.372 8.28883 6.41667 7.00016 6.41667C5.7115 6.41667 4.66683 5.372 4.66683 4.08333C4.66683 2.79467 5.7115 1.75 7.00016 1.75C8.28883 1.75 9.3335 2.79467 9.3335 4.08333Z"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PersonSvg;
